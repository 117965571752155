import React, { FC } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Typography, Box } from '@material-ui/core'
import Image from '@/atoms/SanityImage'
import { Link } from '@/atoms/link'
import { theme } from '@/styles/theme'
import { useWindowDimensions } from '@/hooks/useWindow'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useTimeout } from 'usehooks-ts'
import { getUri } from '@/utils/routing'

gsap.registerPlugin(ScrollTrigger)

const GalleryContainer = styled(Box)`
  @media screen and (max-width: 1024px) {
    div:first-child {
      // padding-top: 0;
    }
    div:last-child {
      // padding-bottom: 1.5rem;
    }
  }
`

const StyledTextBox = styled(Box)`
  &:hover + .intro-gallery-text {
    display: unset;
  }
`

const StyledTypography = styled(Typography)`
  &:hover {
    color: ${theme.colors.blue};
  }
`

type IntroGalleryItem = {
  _key: string
  _type: string
  image: any
  title: string
  description: string
  link: any
  isAlignTop: boolean
}

export type IntroGalleryProps = {
  _key: string
  _type: string
  introGalleryItem: IntroGalleryItem[]
}

const IntroGallery: FC<IntroGalleryProps> = props => {
  const { width } = useWindowDimensions()
  const { introGalleryItem } = props

  const nItems = introGalleryItem.length + 2
  const containerSize = nItems * 600

  useTimeout(() => {
    if (width && width > 1024) {
      const tl = gsap.timeline({
        repeat: 0,
        scrollTrigger: {
          trigger: '.scroll-container',
          scrub: true,
          pin: true,
          start: 'top top',
          end: `+=${containerSize}`
        }
      })

      tl.fromTo(
        '.gallery-container',
        { xPercent: 0 },
        { xPercent: (-100 * (nItems - 2)) / nItems, duration: nItems - 1 }
      )

      let updateGreater = 0,
        updateSmaller = 0,
        updating = 0

      tl.eventCallback(
        'onComplete',
        function() {
          updating = 0
          updateGreater = 0
          updateSmaller = 0
          window.scrollTo(containerSize * 0.0002, containerSize * 0.0002)
        },
        [updateGreater, updateSmaller, updating]
      )
      tl.eventCallback(
        'onUpdate',
        function() {
          if (tl._time > 0.1) {
            updateGreater = 1
          } else if (tl._time < 0.0001) {
            updateSmaller = 1
            if (!updating && updateGreater) {
              updating = 1
              window.scrollTo(0.998 * containerSize, 0.998 * containerSize)
              updating = 0
              updateGreater = 0
            }
          } else if (tl._time > 0.99998 * (nItems - 1)) {
            updating = 0
            updateGreater = 0
            updateSmaller = 0
          }
        },
        [updateGreater, updateSmaller, updating]
      )
      return () => {
        tl.kill()
      }
    }
  }, [200])

  return (
    <Box height={{ xs: 'webkit-fill-available', sm: `${containerSize}px` }}>
      <Box
        py={{ xs: '1.5rem', sm: '3rem' }}
        width={{ xs: '100%', sm: '100%' }}
        height={{ xs: '100%', sm: '100vh' }}
        className="scroll-container"
      >
        <GalleryContainer
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          height={{ xs: `${(nItems - 2) * 50}%`, sm: '100%' }}
          width={{ xs: '100vw', sm: `${nItems * 50}%` }}
          className="gallery-container"
        >
          {introGalleryItem.map((item, index) => (
            <Box
              position="relative"
              className="gallery-item"
              px={{ xs: '3rem', sm: '1.5rem' }}
              py={{ xs: '1.5rem', sm: 0 }}
              height={{ xs: 'calc(50vh - 1.5rem)', sm: '100%' }}
              width={{ xs: '100%', sm: `${100 / nItems}%` }}
            >
              <Image image={item?.image} width={1024} />
              <StyledTextBox
                className="intro-gallery-title"
                position="absolute"
                top={item?.isAlignTop && '3rem'}
                bottom={!item?.isAlignTop && '5rem'}
                left="4.5rem"
                maxWidth={{
                  xs: '70vw',
                  sm: 'calc(50vw - 25rem)',
                  md: '50rem'
                }}
              >
                <Link
                  to={
                    (item?.link?.slug?.current &&
                      item?.link._type &&
                      getUri(
                        item?.link.slug.current,
                        item?.link._type,
                        item.queryParameter
                      )) ||
                    undefined
                  }
                  variant="h2"
                  underline="none"
                >
                  <StyledTypography variant="h2">
                    {item?.title}
                  </StyledTypography>
                </Link>
              </StyledTextBox>
            </Box>
          ))}
          {width && width > 1024 && (
            <>
              <Box
                position="relative"
                className="gallery-item"
                px={{ xs: '3rem', sm: '1.5rem' }}
                py={{ xs: '1.5rem', sm: 0 }}
                height={{ xs: `${100 / nItems}%`, sm: '100%' }}
                width={{ xs: '100%', sm: `${100 / nItems}%` }}
              >
                <Image image={introGalleryItem[0].image} width={1024} />
                <StyledTextBox
                  className="intro-gallery-title"
                  position="absolute"
                  top={introGalleryItem[0]?.isAlignTop && '3rem'}
                  bottom={!introGalleryItem[0]?.isAlignTop && '5rem'}
                  left="4.5rem"
                  maxWidth={{
                    xs: '70vw',
                    sm: 'calc(50vw - 25rem)',
                    md: '50rem'
                  }}
                >
                  <Link
                    to={
                      (introGalleryItem[0]?.link?.slug?.current &&
                        introGalleryItem[0]?.link._type &&
                        getUri(
                          introGalleryItem[0]?.link.slug.current,
                          introGalleryItem[0]?.link._type
                        )) ||
                      undefined
                    }
                    variant="h2"
                    underline="none"
                  >
                    <StyledTypography variant="h2">
                      {introGalleryItem[0]?.title}
                    </StyledTypography>
                  </Link>
                </StyledTextBox>
              </Box>
              <Box
                position="relative"
                className="gallery-item"
                px={{ xs: '3rem', sm: '1.5rem' }}
                py={{ xs: '1.5rem', sm: 0 }}
                height={{ xs: `${100 / nItems}%`, sm: '100%' }}
                width={{ xs: '100%', sm: `${100 / nItems}%` }}
              >
                <Image image={introGalleryItem[1].image} width={1024} />
                <StyledTextBox
                  className="intro-gallery-title"
                  position="absolute"
                  top={introGalleryItem[1]?.isAlignTop && '3rem'}
                  bottom={!introGalleryItem[1]?.isAlignTop && '5rem'}
                  left="4.5rem"
                  maxWidth={{
                    xs: '70vw',
                    sm: 'calc(50vw - 25rem)',
                    md: '50rem'
                  }}
                >
                  <Link
                    to={
                      (introGalleryItem[1]?.link?.slug?.current &&
                        introGalleryItem[1]?.link._type &&
                        getUri(
                          introGalleryItem[1]?.link.slug.current,
                          introGalleryItem[1]?.link._type
                        )) ||
                      undefined
                    }
                    variant="h2"
                    underline="none"
                  >
                    <StyledTypography variant="h2">
                      {introGalleryItem[1]?.title}
                    </StyledTypography>
                  </Link>
                </StyledTextBox>
              </Box>
            </>
          )}
        </GalleryContainer>
      </Box>
    </Box>
  )
}

export default IntroGallery

export const query = graphql`
  fragment moduleIntroGalleryData on SanityModuleIntroGallery {
    _key
    _type
    delay
    speed
    introGalleryItem {
      image {
        ...Image
      }
      queryParameter
      isAlignTop
      link {
        ... on SanityStore {
          _type
          slug {
            current
          }
        }
        ... on SanityPost {
          _type
          slug {
            current
          }
        }
        ... on SanityPage {
          _type
          slug {
            current
          }
        }
        ... on SanityGuide {
          _type
          slug {
            current
          }
        }
      }
      title
    }
  }
`
